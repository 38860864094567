<template>
  <b-row>
    <b-col cols="12" v-if="mainData.est_reach">
      <div class="d-flex mt-1">
        <p class="custom-p font-title-gilroy">
          {{ $t('profile.estimate_reach') }}
          <b-icon
            font-scale="1"
            icon="question-circle"
            class="icon-tooltip"
            id="estimate_reach"
          ></b-icon>
          <b-tooltip target="estimate_reach" triggers="hover" variant="primary">
            {{$t('profile.estimatedScopeInfo')}}
          </b-tooltip>
        </p>
      </div>
       <h4 v-if="mainData.est_reach"><strong>{{utils.getFormat(mainData.est_reach.from)}} - {{utils.getFormat(mainData.est_reach.to)}} </strong></h4>
    </b-col>
    <b-col cols="6">
      <p class="custom-p font-title-gilroy">
        {{ $t('profile.audience_quality') }}
        <b-icon
          font-scale="1"
          icon="question-circle"
          class="icon-tooltip"
          id="estimate_reach_1"
        ></b-icon>
        <b-tooltip target="estimate_reach_1" triggers="hover" variant="primary">
          {{$t('profile.estimatedScopeInfo')}}
        </b-tooltip>
      </p>
      <div class="d-flex">
          <h4 class="ml-2 mb-0">
            <strong>{{$t(`instagram.${mainData.audience_reachability.title}`)}}</strong>
          </h4>
      </div>
    </b-col>

    <b-col cols="6">
      <p class="custom-p font-title-gilroy">
        {{ $t('profile.authenticAudience') }}
        <b-icon
          font-scale="1"
          icon="question-circle"
          class="icon-tooltip"
          id="authentic_audience"
        ></b-icon>
        <b-tooltip target="authentic_audience" triggers="hover" variant="primary">
          {{$t('profile.estimatedScopeInfo')}}
        </b-tooltip>
      </p>
      <div class="d-flex">
        <h4 class="ml-2 mb-0"><strong>{{$t(`instagram.${mainData.audience_authenticity.title}`)}}</strong></h4>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BIcon, BTooltip } from 'bootstrap-vue'
import utils from '@/libs/utils'

export default {
  components: {
    BRow,
    BCol,
    BIcon,
    BTooltip
  },
  props: {
    mainData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      utils
    }
  },
  created() {
  },
  computed: {

  },
  methods: {
  }
}
</script>

<style type="text/css">
  .custom-p {
    font-size: 16px;
    color: #626262;
    font-weight: 400;
    font-family: Montserrat,Helvetica,Arial,sans-serif;
  }
  .notas {
    font-size: 12px;
  }
  .circle-alcance{
    width:15px;
    height:15px;
    border-radius:50%;
  }
  .aprovado{
    background: green;
  }
  .promedio{
      background: orange;
  }
  .force-shadow {
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
  }
  .justify-content-space-evenly {
    justify-content: space-evenly;
  }
  .text-gray {
    color: gray;
  }
</style>
