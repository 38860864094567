<template>
  <div>
    <b-card class="card-instagram-overview">
      <b-row>
        <b-col cols="12" class="col-sm-6 col-lg-4 border-right-lg border-right-md">
          <div class="border-bottom pb-2">
              <p class="custom-p font-title-gilroy-h3">{{ $t('instagram.influenceLevel')}}</p>
              <h2><strong>{{mainData.aqs}} / 100</strong></h2>
              <b-progress height="4px" :value="mainData.aqs" :max="100" />
          </div>

          <div class="mt-4">
              <p class="custom-p font-title-gilroy-h3">Engagement Rate</p>
              <h2><strong>{{mainData.er.value > 0 ? utils.decimalFixed(mainData.er.value, 2) : utils.decimalFixed(mainData.er.avg, 2)}} %</strong></h2>
          </div>
        </b-col>

        <b-col cols="12" class="col-sm-6 col-lg-4 border-sm-right border-right-lg card-instagram-overview">
          <div class="border-bottom pb-2">
            <p class="custom-p font-title-gilroy-h3">{{ $t('instagram.Estimatedinteractions')}}</p>
            <h2><strong>{{utils.getFormat(authEngagement())}} / Post</strong></h2>
            <b-progress height="4px" :value="mainData.followers_count" :max="1000" />
          </div>

          <div class="mt-1 border-bottom pb-2">
            <p class="custom-p font-title-gilroy-h3">{{ $t('instagram.followersTooltip') }}</p>
            <h2><strong>{{utils.getFormat(mainData.followers_count)}}</strong></h2>
          </div>

          <estimate-reach :mainData="mainData" v-if="role !== 'espejo'"/>
        </b-col>

        <b-col cols="12" class="col-sm-6 col-lg-4 border-top-lg mt-2 mt-lg-0">
          <div class="border-bottom pb-2 mt-2 mt-lg-0">
              <p class="custom-p font-title-gilroy-h3">{{ $t('instagram.qualityAudience')}}</p>
              <h2><strong>{{utils.getFormat(aqsFormated)}}</strong></h2>
              <b-progress height="4px" :value="aqs" :max="100" />
          </div>

          <div class="mt-1" v-if="role !== 'espejo'">
            <p class="custom-p font-title-gilroy-h3">
              {{ $t('profile.estimate_price') }} (MXN)
              <b-icon
                font-scale="1"
                icon="question-circle"
                class="icon-tooltip"
                id="estimate_price"
              ></b-icon>
              <b-tooltip target="estimate_price" triggers="hover" variant="primary">
                {{$t('profile.estimatedPriceInfo')}}
              </b-tooltip>
            </p>
            <b-row>
              <b-col cols="6" class="text-center">
                <h4 v-if="price_post > 0"><strong>${{utils.numberFormat(price_post)}}</strong></h4>
                <h4 v-else><strong>{{ $t('notAvailable') }}</strong></h4>
                <p class="notas">{{$t('profile.Publication')}}</p>
              </b-col>

              <b-col cols="6" class="text-center">
                <h4 v-if="price_story > 0"><strong>${{utils.numberFormat(price_story)}}</strong></h4>
                <h4 v-else><strong>{{ $t('notAvailable') }}</strong></h4>
                <p class="notas">Story</p>
              </b-col>
            </b-row>
            <div>
              <p class="custom-p font-title-gilroy-h3">
                {{$t('profile.fakeFollowers')}}
              </p>
              <b-col cols="6" class="container-percentage">
                <b-badge :class="badgeColor(totalFollowersFake())" :variant="badgeColor(totalFollowersFake())"><strong>{{ totalFollowersFake() }}%</strong></b-badge>
              </b-col>
            </div>
          </div>
          <estimate-reach :mainData="mainData" v-else />
        </b-col>

        <b-col cols="12" class="border-top mt-2 pt-2" v-if="mainData.advertising_data">
          <h4><strong class="font-title-gilroy-h4">{{ $t('profile.brandMentions') }}</strong></h4>
          <b-row>
            <b-col cols="12" class="col-sm-6 col-md-4 col-lg-3" v-if="mainData.advertising_data">
              <p class="custom-p font-title-gilroy">
                {{ $t('profile.marketingPerformance') }}
                <b-icon
                  font-scale="1"
                  icon="question-circle"
                  class="icon-tooltip"
                  id="marketingPerformance"
                ></b-icon>
                <b-tooltip target="marketingPerformance" triggers="hover" variant="primary">
                  {{$t('profile.estimatedScopeInfo')}}
                </b-tooltip>
              </p>
              <div class="d-flex centerContent">
                <div class="circle-alcance aprovado"></div>
                <h4 class="ml-1 mb-0">
                  <strong>{{$t(`instagram.${mainData.advertising_data.avg_er_display[1]}`)}}</strong>
                </h4>
              </div>
            </b-col>

            <b-col cols="12" class="col-sm-6 col-md-4 col-lg-3 mt-3 mt-lg-0" v-if="mainData.advertising_data">
              <p class="custom-p font-title-gilroy">
                {{ $t('profile.postFrequency') }}
                <b-icon
                  font-scale="1"
                  icon="question-circle"
                  class="icon-tooltip"
                  id="postFrequencyInfo"
                ></b-icon>
                <b-tooltip target="postFrequencyInfo" triggers="hover" variant="primary">
                  {{$t('profile.postFrequencyInfo')}}
                </b-tooltip>
              </p>
              <div class="d-flex centerContent">
                <div class="circle-alcance promedio"></div>
                <h4 class="ml-2 mb-0">
                  <strong>{{$t(`instagram.${mainData.advertising_data.all_posts.display[1]}`)}}</strong>
                </h4>
              </div>
            </b-col>

            <b-col cols="12" class="col-sm-6 col-md-4 col-lg-3 mt-3 mt-lg-0" v-if="mainData.advertising_data">
              <p class="custom-p font-title-gilroy">
                {{ $t('profile.postFrequencyCommercial') }}
                <b-icon
                  font-scale="1"
                  icon="question-circle"
                  class="icon-tooltip"
                  id="postFrequencyCommercialInfo"
                ></b-icon>
                <b-tooltip target="postFrequencyCommercialInfo" triggers="hover" variant="primary">
                  {{$t('profile.postFrequencyCommercialInfo')}}
                </b-tooltip>
              </p>

              <div class="d-flex centerContent">
                <div class="circle-alcance promedio"></div>
                <h4 class="ml-2 mb-0">
                  <strong>{{$t(`instagram.${mainData.advertising_data.ad_posts.display[1]}`)}}</strong>
                </h4>
              </div>
            </b-col>

            <b-col cols="12" class="col-sm-6 col-md-4 col-lg-3 mt-3 mt-lg-0" v-if="mainData.advertising_data">
              <p class="custom-p font-title-gilroy">
                {{ $t('profile.brandMentions') }}
                <b-icon
                  font-scale="1"
                  icon="question-circle"
                  class="icon-tooltip"
                  id="brandMentionsInfo"
                ></b-icon>
                <b-tooltip target="brandMentionsInfo" triggers="hover" variant="primary">
                  {{$t('profile.brandMentionsInfo')}}
                </b-tooltip>
              </p>
              <div class="d-flex centerContent">
                <h4 class="mb-0"><strong>{{mainData.advertising_data.brands_mentions_count}} {{ $t('brand') }}</strong></h4>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-row class="justify-content-space-evenly" v-if="brands.length > 0 && brands_convert === brands.length">
      <b-col
        v-for="(brand, index) in brands"
        :key="index"
        cols="12"
        class="col-sm-4 col-md-3 col-lg-2 d-flex align-items-stretch"
      >
        <b-card no-body>
          <div class="p-1 text-center">
            <b-img
              :src="brand.image_brand" 
              class="responsive rounded-lg w-100 img-150"
              @error="errorLoad(brand, 'update_image')" 
              :key="update_image"
            ></b-img>
            <h5 class="mb-2 mt-2">@{{brand.username}}</h5>
            <div class="d-flex justify-content-between flex-wrap border-top pb-2">
              <p class="text-gray notas mt-2">{{brand.mentions_count}} {{ $t('tiktok.mentions') }}</p>
              <span class="mt-2">
                <p class="text-gray notas">
                  TP: {{brand.mention_er}}%
                  <b-icon
                    font-scale="1"
                    icon="question-circle"
                    class="icon-tooltip"
                    :id="'estimatedScopeInfo_' + index"
                  ></b-icon>
                  <b-tooltip :target="'estimatedScopeInfo_' + index" triggers="hover" variant="primary">
                    {{$t('profile.estimatedScopeInfo')}}
                  </b-tooltip>
                </p>
              </span>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="justify-content-space-evenly" v-if="other_brands.length > 0">
      <b-button @click="collapse = !collapse" variant="outline-primary" class="mb-2 text-center">
        {{!collapse ? $t('instagram.seeMore') : $t('instagram.seeLess')}}
        <feather-icon v-if="!collapse" icon="ChevronDownIcon"/>
        <feather-icon v-if="collapse" icon="ChevronUpIcon"/>
      </b-button>
    </b-row>
    <b-collapse v-model="collapse">
      <b-row class="justify-content-space-evenly" v-if="other_brands.length > 0">
        <b-col
          v-for="(brand, index) in other_brands"
          :key="index"
          cols="12"
          class="col-sm-4 col-md-3 col-lg-2 mr-2 ml-2 ml-sm-0 d-flex align-items-stretch"
        >
          <b-card no-body>
            <div class="p-1 text-center">
              <b-img
                :src="brand.image_brand" 
                class="responsive rounded-lg w-100 img-150" 
                @error="errorLoad(brand, 'update_image_collapse')"
                :key="update_image"
              ></b-img>
              <h5 class="mb-2 mt-2">@{{brand.username}}</h5>
              <div class="d-flex justify-content-between flex-wrap border-top pb-2">
                <p class="text-gray notas mt-2">{{brand.mentions_count}} {{ $t('mentions') }}</p>
                <span class="mt-2">
                  <p class="text-gray notas">
                    TP: {{brand.mention_er}}%
                    <b-icon
                      font-scale="1"
                      icon="question-circle"
                      class="icon-tooltip"
                      :id="'estimatedScopeInfo_' + index"
                    ></b-icon>
                    <b-tooltip :target="'estimatedScopeInfo_' + index" triggers="hover" variant="primary">
                      {{$t('profile.estimatedScopeInfo')}}
                    </b-tooltip>
                  </p>
                </span>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-collapse>

  </div>
</template>

<script>
import { 
  BBadge,
  BCard, 
  BRow, 
  BCol, 
  BProgress, 
  BIcon, 
  BTooltip, 
  BCollapse, 
  BButton, 
  VBToggle, 
  BImg,
} from 'bootstrap-vue'
import utils from '@/libs/utils'
import EstimateReach from './EstimateReach.vue'

export default {
  components: {
    BBadge,
    BCard,
    BRow,
    BCol,
    BProgress,
    BIcon,
    BTooltip,
    BCollapse,
    BButton,
    EstimateReach,
    BImg,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    mainData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      utils,
      role: 'brand',
      brand_images: [],
      brands: this.mainData.advertising_data && this.mainData.advertising_data.brands_mentions ? this.mainData.advertising_data.brands_mentions.slice(0, 5) : [],
      other_brand_images: [],
      convert_other_brand: true,
      other_brands: this.mainData.advertising_data && this.mainData.advertising_data.brands_mentions ? this.mainData.advertising_data.brands_mentions.slice(5, this.mainData.advertising_data.brands_mentions.length) : [],
      other_brands_convert: 0,
      collapse: false,
      brands_convert: 0,
      update_image: false,
      update_image_collapse: false,
    }
  },
  created() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.role = userData.groups[0].name

    this.moreBrandsOpen();
    const self = this;
    this.brands.forEach(brand => {
      utils.urlImagetoBase64(brand.avatar, function (result) {
        self.brand_images.push(
          {
            'key': brand.username,
            'image': result
          }
        )
        self.brands_convert = self.brands_convert + 1;
        brand['image_brand'] = self.getImageBrand(brand.username, self.brand_images);
      });
    });
  },
  computed: {
    prices () {
      return this.mainData.estimation.map((i) => {
        const cont = [
          {
            content_type : 'photo',
            qty: 0,
            cpp: i.photo,
            subtotal: 0
          },
          {
            content_type : 'video',
            qty: 0,
            cpp: i.video,
            subtotal: 0
          },
          {
            content_type : 'story',
            qty: 0,
            cpp: i.story,
            subtotal: 0
          },
          {
            content_type : 'streaming',
            qty: 0,
            cpp: i.live,
            subtotal: 0
          }
        ]
        const obj = {network: i.network, content: cont}
        return obj
      })
    },
    aqs() {
      const val = this.mainData.audience_type['infs'] + this.mainData.audience_type['real']
      if (val > 0) return Math.round(val)
      return 'No hay datos'
    },
    aqsFormated() {
      const val = this.mainData.followers_count * this.aqs / 100
      if (this.aqs !== 'No hay datos') return val
      return this.aqs
    },
    price_post () {
      let price = 0
      if (typeof this.prices === 'string') return price
      this.prices.forEach(function(network) {
        network.content.forEach(function(content) {
          if (content.content_type === 'photo') price = content.cpp
          else if (content.content_type === 'video') price = content.cpp
        });
      });

      return price;
    },
    price_story () {
      let price = 0
      if (typeof this.prices === 'string') return price
      this.prices.forEach(function(network) {
        network.content.forEach(function(content) {
          if (content.content_type === 'story') price = content.cpp
        });
      });

      return price;
    }
  },
  methods: {
    moreBrandsOpen() {
      if (this.convert_other_brand) {
        this.convert_other_brand = false
        const self = this;
        this.other_brands.forEach(brand => {
          utils.urlImagetoBase64(brand.avatar, function (result) {
            self.other_brand_images.push(
              {
                'key': brand.username,
                'image': result
              }
            )
            self.other_brands_convert = self.other_brands_convert + 1;
            brand['image_brand'] = self.getImageBrand(brand.username, self.other_brand_images);
          });
        });
      }
    },
    authEngagement () {
      const audienceAuth = parseFloat(this.mainData.audience_authenticity.value)
      const followers = parseFloat(this.mainData.followers_count)
      const er = 3.46
      const result = (er / 100) * followers * (audienceAuth / 100)
      return Math.round(result)
    },
    getImageBrand(brand, images) {
      if (images.length === 0) return ''

      const image = images.find(image => image.key === brand);
      return image.image
    },
    errorLoad(brand, update) {
      brand.image_brand = require("@/assets/images/dashboard/imgDefault.png");
      this[update] = !this[update];
    },
    totalFollowersFake() {
      let totalFake = 0
      totalFake = (this.mainData.followers_count - this.aqsFormated) / this.mainData.followers_count * 100
      return Math.round(totalFake)
    },
    badgeColor(level) {

      if (level <= 29) return 'success'
      if (level <= 60) return 'warning'
      if (level <= 79) return 'warning-strong'
      if (level <= 100) return 'danger'

      return 'primary'
    }

  }
}
</script>

<style type="text/css">
  .img-150 {
    width: 150px !important;
    height: 150px !important;
    object-fit: cover;
  }
  .custom-p {
    font-size: 16px;
    color: #626262;
    font-weight: 400;
    font-family: Montserrat,Helvetica,Arial,sans-serif;
  }
  .notas {
    font-size: 12px;
  }
  .circle-alcance{
    width:15px;
    height:15px;
    border-radius:50%;
  }
  .aprovado{
    background: green;
  }
  .promedio{
      background: orange;
  }
  .peligro {
    background: red;
  }
  .force-shadow {
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
  }
  .justify-content-space-evenly {
    justify-content: space-evenly;
  }
  .text-gray {
    color: gray;
  }
  @media(min-width: 576px) {
    .border-right-md {
      border-right: 1px solid #F0EFF4;
      height: 350px;
    }
  }
   @media(min-width: 992px) {
    .border-right-lg {
      border-right: 1px solid #F0EFF4;
      height: 350px;
    }
  }
   @media(max-width: 992px) {
    .border-top-lg {
      border-top: 1px solid #F0EFF4;
    }
  }
</style>
<style>
.container-percentage {
  padding-left: 0px;
  text-align: start;
  font-size: large;
}
.card-instagram-overview > .card-body,
.card-instagram-overview {
  height: 100% !important;
}
.warning-strong{
  background: #fd7e14 !important;
}
</style>
